<template>
  <b-card
    header-bg-variant="warning"
    header-class="py-25 px-1 rounded_header"
    class="rounded-lg mb-50 mb-lg-1"
    no-body
  >
    <template #header>
      <div
        class="w-100 d-flex-between text-white cursor-pointer"
        @click="toggleVisibleTrips"
      >
        <span :class="`fw-700 ${isMobileView ? 'font-small-4' : ''}`">
          {{ $t('flight.flight') }}
        </span>

        <div class="p-25">
          <IconSvg
            :src="require('@icons/triangle-down.svg')"
            blank-color="#ccc"
            class="py-25 rotate_class text-white"
            :style="{transform: rotateStyleTripsAll, width: '18px'}"
          />
        </div>
      </div>
    </template>

    <!-- ANCHOR - CHI TIẾT CHUYẾN BAY -->
    <div
      v-for="(trip, tripIndex) in searchFlightArray"
      :key="tripIndex"
      :class="`cursor-pointer ${tripIndex === tabIndex ? 'border-left-5-warning' : 'border-left-5-white'}`"
      style="border-bottom: 1px dashed #dddddd;"
    >
      <b-card
        :id="`tripItem-${tripIndex}`"
        class="mb-25"
        header-class="p-50"
        body-class="p-50"
        no-body
      >
        <template #header>
          <div @click.stop="handleClickTab(tripIndex)">
            <div class="w-100 d-flex-between">
              <div class="d-flex w-100">
                <div class="mr-50 d-flex-center">
                  <b-avatar
                    size="27"
                    :variant="tripIndex === tabIndex ? 'warning' : 'secondary'"
                  >
                    {{ tripIndex + 1 }}
                  </b-avatar>
                </div>
                <div class="d-flex-center justify-content-start w-100">
                  <div
                    class="text-heading-4"
                    style="line-height: 24px; font-weight: 800;"
                    :class="tripIndex === tabIndex ? 'text-warning' : 'text-secondary'"
                  >
                    {{ getAirportName(trip.startPoint) }}
                  </div>
                  <div class="d-flex-center">
                    <IAmIcon
                      v-if="isRTType"
                      icon="swap"
                      class="mx-25 d-flex-center"
                      size="20px"
                      :class="`${tripIndex === tabIndex ?'text-warning' : 'text-secondary'}`"
                    />
                    <feather-icon
                      v-else
                      size="20"
                      icon="ArrowRightIcon"
                      :class="`mx-25 ${tripIndex === tabIndex ?'text-warning' : 'text-secondary'}`"
                      style="stroke-width: 3;"
                    />
                  </div>

                  <div
                    class="text-heading-4"
                    style="line-height: 24px; font-weight: 800;"
                    :class="tripIndex === tabIndex ? 'text-warning' : 'text-secondary'"
                  >
                    {{ getAirportName(trip.endPoint) }}
                  </div>
                </div>
              </div>
            </div>

            <div class="font-small-4 fw-800 ml-50 text-dark">
              <div v-if="resolveSelectedTrip?.trips[tripIndex]">
                {{ convertISODateTimeLangVN(resolveSelectedTrip?.trips[tripIndex].departure.at, resolveSelectedTrip?.trips[tripIndex].departure.timezone).shortDay }}
                <b-img
                  blank-color="#ccc"
                  src="@icons/column.svg"
                  class="py-0 px-50"
                />
                {{ convertISODateTime(resolveSelectedTrip?.trips[tripIndex].departure.at, resolveSelectedTrip?.trips[tripIndex].departure.timezone).time }} - {{ convertISODateTime(resolveSelectedTrip?.trips[tripIndex].arrival.at, resolveSelectedTrip?.trips[tripIndex].arrival.timezone).time }}
              </div>
              <div
                v-else
                :class="`${tripIndex === tabIndex ? 'text-dark' : 'text-muted'}`"
              >
                {{ convertISODateTimeLangVN(trip.departDate).shortDay }}
              </div>
            </div>
          </div>
        </template>
        <b-collapse
          :id="`tripItem-${tripIndex}`"
          v-model="isVisibleTrips[tripIndex]"
          class="ml-75"
          role="tabpanel"
        >
          <!-- ANCHOR - THÔNG TIN CHUYẾN BAY ĐÃ CHỌN -->

          <div v-if="resolveSelectedTrip?.trips[tripIndex]">
            <div class="d-flex flex-wrap font-small-4 align-items-center fw-800">
              <div
                class="text-nowrap"
                :style="resolveColorFollowAirline(resolveSelectedTrip?.trips[tripIndex].airline)"
              >
                {{ `${resolveAirlineFlightNumber(resolveSelectedTrip?.trips[tripIndex]?.airline, resolveSelectedTrip?.trips[tripIndex]?.flightNumber || resolveSelectedTrip?.trips[tripIndex]?.segments[0]?.flightNumber)}` }}
              </div>
              <b-img
                blank-color="#ccc"
                src="@icons/dot.svg"
                class="m-50"
              />
              <div class="text-nowrap">
                {{ convertShortenAircraft(resolveSelectedTrip?.trips[tripIndex]?.segments[0]?.airCraft) }}
              </div>
              <b-img
                blank-color="#ccc"
                src="@icons/dot.svg"
                class="m-50"
              />
              <div class="text-nowrap text-truncate">
                {{ resolveSelectedTrip?.trips[tripIndex]?.operator === 'QH' ? resolveSelectedTrip?.trips[tripIndex]?.fareOptions?.fareType : (resolveSelectedTrip?.trips[tripIndex]?.fareOptions?.groupClass || resolveSelectedTrip?.trips[tripIndex]?.bookingClass) }}
              </div>
              <IAmLogoAirline
                :airline="(['BL'].includes(resolveSelectedTrip?.trips[tripIndex]?.segments[0]?.operating) ? 'BL' : null) || resolveSelectedTrip?.trips[tripIndex]?.airline?.split(' | ')[0] || resolveSelectedTrip?.trips[tripIndex]?.operator "
                custom-class="mr-50 ml-auto px-25"
              />
            </div>
            <!-- ANCHOR - price -->
            <div v-if="!isCombination">
              <AppCollapseItem
                :id="`priceItem-${tripIndex}`"
                :is-visible="isVisiblePrice[tripIndex]"
                title
                class="mb-0"
                :class-header="`p-0`"
                :class-body="`p-0`"
                no-body
              >
                <template #header>
                  <div
                    :id="`priceItem-toggle-${tripIndex}`"
                    class="w-100 d-flex flex-nowrap align-items-center text-warning cursor-pointer mt-0 mb-50"
                    style="max-width: 520px;"
                    @click="toggleVisiblePrice(tripIndex)"
                  >
                    <div
                      v-if="isVisiblePriceFareCol"
                      class="d-flex flex-nowrap align-items-center"
                    >
                      <span class="fw-700 font-medium-2">
                        {{ formatCurrency(resolveFarePrice(resolveSelectedTrip.trips[tripIndex]).total) }}
                      </span>
                      <div>
                        <b-img
                          blank-color="#ccc"
                          src="@icons/arrow_warning.svg"
                          class="m-50 rotate_class"
                          :style="{transform: rotateStylePrice(tripIndex)}"
                        />
                      </div>
                    </div>
                  </div>
                </template>

                <div class="text-airline font-weight-bolder">
                  <div
                    v-for="(fareItem, fareIndex) in resolveFarePrice(resolveSelectedTrip.trips[tripIndex]).paxPrice"
                    :key="fareIndex"
                  >
                    <b-row
                      v-if="fareItem.quantity"
                      class="mx-0 font-small-3 flex-nowrap fw-700 d-flex-between"
                      no-gutters
                    >
                      <b-col
                        v-if="(fareItem.fare || (fareItem.surcharge + fareItem.tax) > 0 || (['TH', 'AK'].includes(resolveSelectedTrip.trips[tripIndex].source))) && isVisiblePriceFareCol"
                        cols="2"
                        lg="2"
                      >
                        <div class="d-flex-between">
                          <span>{{ fareItem.title }}</span>
                          <span>x{{ fareItem.quantity }}</span>
                        </div>
                      </b-col>
                      <b-col
                        v-if="fareItem?.title === 'INF' && (['AK'].includes(resolveSelectedTrip.trips[tripIndex].source) || (['TH'].includes(resolveSelectedTrip.trips[tripIndex].source) && fareItem?.fare === 0))"
                        cols="10"
                        lg="10"
                        class="d-flex-center justify-content-end"
                      >
                        <span class="text-danger text-nowrap font-small-2">Vui lòng vào màn nhập thông tin để kiểm tra giá em bé</span>
                      </b-col>
                      <b-col
                        v-if="isVisiblePriceFareCol && !(fareItem?.title === 'INF' && (['AK'].includes(resolveSelectedTrip.trips[tripIndex].source) || (['TH'].includes(resolveSelectedTrip.trips[tripIndex].source) && fareItem?.fare === 0)))"
                        cols="3"
                        md="2"
                        lg="3"
                        class="text-nowrap text-right"
                      >
                        {{ formatCurrency(fareItem.priceFareCol) }}
                      </b-col>
                      <b-col
                        v-if="isVisibleFeeCol && !(fareItem?.title === 'INF' && (['AK'].includes(resolveSelectedTrip.trips[tripIndex].source) || (['TH'].includes(resolveSelectedTrip.trips[tripIndex].source) && fareItem?.fare === 0)))"
                        cols="3"
                        md="2"
                        lg="3"
                        class="text-nowrap text-right"
                      >
                        {{ formatCurrency(fareItem.priceFeeCol) }}
                      </b-col>
                      <b-col
                        v-if="isVisibleTotalFareCol && !(fareItem?.title === 'INF' && (['AK'].includes(resolveSelectedTrip.trips[tripIndex].source) || (['TH'].includes(resolveSelectedTrip.trips[tripIndex].source) && fareItem?.fare === 0)))"
                        cols="3"
                        md="2"
                        lg="3"
                        class="text-warning text-nowrap text-right"
                      >
                        {{ formatCurrency(fareItem.priceTotalCol) }}
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </AppCollapseItem>
            </div>
          </div>

          <i
            v-else
            class="text-danger"
          >
            {{ $t('flight.noItiSelected') }}
          </i>
        </b-collapse>
      </b-card>
    </div>

    <!-- ANCHOR Giá chi tiết cho giá kết hợp -->
    <div
      v-if="isCombination"
      class="mt-0 mb-50 px-75"
    >
      <div
        class="w-100 d-flex flex-nowrap align-items-center text-warning cursor-pointer "
        style="max-width: 520px;"
        @click="toggleVisiblePriceCombination"
      >
        <div
          v-if="isVisiblePriceFareCol"
          class="d-flex flex-nowrap align-items-center"
        >
          <span class="fw-700 font-medium-2">
            {{ formatCurrency(resolveFarePrice(resolveSelectedTrip).total) }}
          </span>
          <div>
            <b-img
              blank-color="#ccc"
              src="@icons/arrow_warning.svg"
              class="m-50 rotate_class"
              :style="{transform: !isVisiblePriceCombination ? 'rotate(0deg)' : 'rotate(180deg)'}"
            />
          </div>
        </div>
      </div>
      <b-collapse
        id="priceItem-toggle-combination"
        v-model="isVisiblePriceCombination"
        role="tabpanel"
      >
        <div class="text-airline font-weight-bolder">
          <div
            v-for="(fareItem, fareIndex) in resolveFarePrice(resolveSelectedTrip).paxPrice"
            :key="fareIndex"
          >
            <b-row
              v-if="fareItem.quantity"
              class="mx-0 font-small-3 flex-nowrap fw-700 d-flex-between"
              no-gutters
            >
              <b-col
                v-if="(fareItem.fare || (fareItem.surcharge + fareItem.tax) > 0) && isVisiblePriceFareCol"
                cols="2"
                lg="2"
              >
                <div class="d-flex-between">
                  <span>{{ fareItem.title }}</span>
                  <span>x{{ fareItem.quantity }}</span>
                </div>
              </b-col>
              <b-col
                v-if="isVisiblePriceFareCol"
                cols="3"
                md="2"
                lg="3"
                class="text-nowrap text-right"
              >
                {{ formatCurrency(fareItem.priceFareCol) }}
              </b-col>
              <b-col
                v-if="isVisibleFeeCol"
                cols="3"
                md="2"
                lg="3"
                class="text-nowrap text-right"
              >
                {{ formatCurrency(fareItem.priceFeeCol) }}
              </b-col>
              <b-col
                v-if="isVisibleTotalFareCol"
                cols="3"
                md="2"
                lg="3"
                class="text-warning text-nowrap text-right"
              >
                {{ formatCurrency(fareItem.priceTotalCol) }}
              </b-col>
            </b-row>
          </div>
        </div>
      </b-collapse>
    </div>

    <!-- ANCHOR - TỔNG TIỀN -->
    <HStack between>
      <div>
        <b-button
          v-if="!isHideTakePhotoItineraries"
          variant="warning"
          class="px-50"
          size="sm"
          @click="onClickTakePhotoItineraries"
        >
          <HStack center>
            <IAmIcon
              icon="camera"
              size="13"
            />
            {{ $t('reservation.takePhotoItineraries') }}
          </HStack>
        </b-button>
      </div>
      <div
        v-if="isVisiblePriceFareCol"
        class="text-airline text-right mr-75 my-50"
      >
        <span
          class="mr-25 mr-md-75 text-nowrap font-weight-bold"
          :class="isMobileView ? 'font-small-4' : ''"
        >
          {{ $t('flight.Total') }}
        </span>
        <span
          class="text-nowrap fw-800"
          :class="isMobileView ? 'font-medium-1': 'font-medium-4'"
        >
          {{ formatCurrency(totalPrice) }}
        </span>
      </div>
    </HStack>

    <!-- ANCHOR - VN1A tính lại giá -->
    <template v-if="isVN1A">
      <div class="mx-50 my-50 text-center border-danger rounded-lg p-50">
        <div :class="`ml-50 text-decoration-danger mr-25 text-danger mr-md-75 fw-700 ${isMobileView ? 'font-small-4' : ''}`">
          Do lỗi hệ thống VN nên vui lòng tính lại giá trước khi báo cho khách.
        </div>
        <BButton
          variant="warning"
          size="sm"
          @click="handleReCalculatePrice()"
        >
          Tính giá
        </BButton>
      </div>
    </template>

    <CalculateTripPriceModalVN1A
      v-if="isVN1A"
      :selected-trip="draftSelectedTrip.filter(trip => !!trip && ['VN1A'].includes(trip.source) && !['INTERNAL'].includes(trip.clientId))"
    />

    <ModalTakePhotoItineraries
      v-if="draftSelectedTrip && !isHideTakePhotoItineraries"
      :selected-trips="isCombination ? combinationSelectedTrip.journeys : draftSelectedTrip"
    >
      <BookingPriceDetailCombination
        v-if="isCombination"
        :selected-trip="combinationSelectedTrip.journeys"
        :data-search-flight="searchFlightData"
        :isTakePhoto="true"
      />

      <booking-price-detail
        v-else-if="searchFlightData && draftSelectedTrip"
        :selected-trip="draftSelectedTrip"
        :data-search-flight="searchFlightData"
        :isTakePhoto="true"
      />
    </ModalTakePhotoItineraries>
  </b-card>
</template>

<script>
import {
  BCard, BImg, BAvatar, BRow, BCol, BCollapse, BButton,
} from 'bootstrap-vue'
import {
  computed,
  ref,
  watch,
  onMounted, watchEffect,
} from '@vue/composition-api'
import isEmpty from 'lodash/isEmpty'

import store from '@/store'
import env from '@/libs/env'

import {
  convertISODateTime,
  convertISODateTimeLangVN,
  formatCurrency,
  toHoursAndMinutes, resolveAirlineFlightNumber,
} from '@core/utils/filter'

import useBookingHandle, { combinateFareOptions } from '@flightv2/useBookingHandle'

import useToast from '@useToast'

import CalculateTripPriceModalVN1A from './CalculateTripPriceModalVN1A.vue'

export default {
  components: {
    BCard,
    BImg,
    BAvatar,
    BRow,
    BCol,
    BCollapse,
    BButton,

    CalculateTripPriceModalVN1A,
    AppCollapseItem: () => import('@core/components/app-collapse/AppCollapseItem.vue'),
    IAmLogoAirline: () => import('@/components/IAmLogoAirline.vue'),
    ModalTakePhotoItineraries: () => import('../../reservation/TakePhotoItineraries/ModalTakePhotoItineraries.vue'),
    BookingPriceDetailCombination: () => import('../../reservation/BookingPriceDetailCombination.vue'),
    BookingPriceDetail: () => import('../../reservation/BookingPriceDetail.vue'),
  },
  computed: {
    tabIndex: {
      get() { return this.$store.getters['app-flight-v2/getTabIndex'] },
      set(newValue) { this.$store.dispatch('app-flight-v2/setTabIndex', newValue) },
    },
  },
  setup() {
    const { toastError } = useToast()
    const { isHideTakePhotoItineraries, isEnableFnSourceAKJSON } = env
    const {
      convertShortenAircraft,
      getSearchFlightArray: searchFlightArray,
      getSearchFlight: searchFlightData,
    } = useBookingHandle()
    const combinationSelectedTrip = computed(() => store.getters['app-flight-v2/getCombinationSelectedTrip'])
    const draftSelectedTrip = computed(() => store.getters['app-flight-v2/getDraftSelectedTrip'])

    const isVN1A = computed(() => Boolean(!isEmpty(draftSelectedTrip.value)
          && draftSelectedTrip.value?.some(trip => !!trip && ['VN1A'].includes(trip?.source)
          && !['INTERNAL'].includes(trip.clientId))))

    // Biến dùng để check RT hay ko => hiển thị icon
    const isRTType = computed(() => searchFlightData?.value?.type === 'RT')
    const isCombination = ref(false)
    const resolveSelectedTrip = computed(() => {
      if (combinationSelectedTrip.value) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        isCombination.value = true
        return {
          trips: combinationSelectedTrip.value.journeys,
          fareOptions: combinateFareOptions(combinationSelectedTrip.value.journeys.map(j => j.fareOptions)),
          source: combinationSelectedTrip.value.source,
          domestic: false,
        }
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      isCombination.value = false
      return {
        trips: draftSelectedTrip.value,
        fareOptions: false,
      }
    })

    const totalPrice = ref(0)
    const getShowPriceAfterDiscount = computed(() => store.getters['app-flight-v2/getShowPriceAfterDiscount'])
    const getCustomFeeServiceDataToModify = computed(() => store.getters['app-flight-v2/getCustomFeeServiceDataToModify'])
    const getShowPrice = computed(() => store.getters['app-flight-v2/getShowPrice'])

    // Visible các cột
    const isVisiblePriceFareCol = computed(() => getShowPrice.value !== 'HIDE_FARE')
    const isVisibleFeeCol = computed(() => !['HIDE_FARE', 'TICKET_FARE'].includes(getShowPrice.value))
    const isVisibleTotalFareCol = computed(() => !['HIDE_FARE', 'TICKET_FARE'].includes(getShowPrice.value))

    const getCustomServiceFee = (pax, fareOptions) => getCustomFeeServiceDataToModify.value[`${pax.toLowerCase()}Amount`] !== null ? getCustomFeeServiceDataToModify.value[`${pax.toLowerCase()}Amount`] - fareOptions[`serviceFee${pax}`] : 0

    const resolveFarePrice = selectedTrip => {
      const fareItem = selectedTrip?.fareOptions
      if (!fareItem) return { total: 0, paxPrice: [] }
      const getFeeServiceInSelectedProfile = computed(() => store.getters['app-flight-v2/getFeeServiceInSelectedProfile'](selectedTrip?.domestic ?? false, selectedTrip?.source))
      const {
        hideFee, agencyFee,
        hideFeeParent, agencyFeeParent,
        totalFeeAdult, totalFeeChild, totalFeeInfant,
        fareAdultAfterDiscount, fareAdultAfterDiscountAndPromotion,
        fareChildAfterDiscount, fareChildAfterDiscountAndPromotion,
        fareInfantAfterDiscount, fareInfantAfterDiscountAndPromotion,
      } = fareItem
      const { adult, child, infant } = fareItem.passenger

      const modifiedServiceFee = {
        adultAmount: null,
        childAmount: null,
        infantAmount: null,
      }
      // Nếu có custom pdv
      if (getCustomFeeServiceDataToModify.value?.adultAmount !== null || getCustomFeeServiceDataToModify.value?.childAmount !== null || getCustomFeeServiceDataToModify.value?.infantAmount !== null) {
        modifiedServiceFee.adultAmount = getCustomFeeServiceDataToModify.value?.adultAmount * fareItem.amountSegmentToMultipleFeeService
        modifiedServiceFee.childAmount = getCustomFeeServiceDataToModify.value?.childAmount * fareItem.amountSegmentToMultipleFeeService
        modifiedServiceFee.infantAmount = getCustomFeeServiceDataToModify.value?.infantAmount * fareItem.amountSegmentToMultipleFeeService
      } else if (getFeeServiceInSelectedProfile.value) {
      // nếu chọn profile pdv
        const isSegmentFeeType = getFeeServiceInSelectedProfile.value?.feeType === 'FLIGHT_SEGMENT'
        const amountSegmentToMultipleFeeService = isSegmentFeeType
          ? (isCombination.value
            ? selectedTrip.trips.reduce((a, c) => a + c.segments.length, 0)
            : selectedTrip?.segments?.length)
          : 1
        modifiedServiceFee.adultAmount = getFeeServiceInSelectedProfile.value?.adultAmount * amountSegmentToMultipleFeeService
        modifiedServiceFee.childAmount = getFeeServiceInSelectedProfile.value?.childAmount * amountSegmentToMultipleFeeService
        modifiedServiceFee.infantAmount = getFeeServiceInSelectedProfile.value?.infantAmount * amountSegmentToMultipleFeeService
      }

      // NOTE: priceFareCol = tiền cột Fare, priceFeeCol = tiền cột phí, !! INF ko tính phí xuất vé
      // Adult
      const priceAdultServiceFee = modifiedServiceFee.adultAmount !== null ? modifiedServiceFee.adultAmount - fareItem.serviceFeeAdult : 0
      const priceFareColAdult = getShowPriceAfterDiscount.value ? fareAdultAfterDiscountAndPromotion : fareAdultAfterDiscount
      let priceFeeColAdult = totalFeeAdult + priceAdultServiceFee
      // Child
      const priceChildServiceFee = modifiedServiceFee.childAmount !== null ? modifiedServiceFee.childAmount - fareItem.serviceFeeChild : 0
      const priceFareColChild = getShowPriceAfterDiscount.value ? fareChildAfterDiscountAndPromotion : fareChildAfterDiscount
      let priceFeeColChild = totalFeeChild + priceChildServiceFee
      // Infant
      const priceInfantServiceFee = modifiedServiceFee.infantAmount !== null ? modifiedServiceFee.infantAmount - fareItem.serviceFeeInfant : 0
      const priceFareColInfant = getShowPriceAfterDiscount.value ? fareInfantAfterDiscountAndPromotion : fareInfantAfterDiscount
      let priceFeeColInfant = totalFeeInfant + priceInfantServiceFee

      if (getShowPrice.value === 'NET_FARE') {
        priceFeeColAdult = fareItem.taxAdult + fareItem.surchargeAdult + (hideFee ? agencyFee : 0) + (hideFeeParent ? agencyFeeParent : 0)
        priceFeeColChild = fareItem.taxChild + fareItem.surchargeChild + (hideFee ? agencyFee : 0) + (hideFeeParent ? agencyFeeParent : 0)
        priceFeeColInfant = fareItem.taxInfant + fareItem.surchargeInfant
      }
      const result = [
        {
          title: 'ADT',
          quantity: adult,
          fare: fareItem.fareAdult, // giá vé gốc từ hãng
          agencyFee: hideFee ? 0 : agencyFee,
          hideFee,
          surcharge: fareItem.surchargeAdult + (hideFee ? agencyFee : 0) + (hideFeeParent ? agencyFeeParent : 0),
          tax: fareItem.taxAdult,
          serviceFee: modifiedServiceFee.adultAmount !== null ? modifiedServiceFee.adultAmount : fareItem.serviceFeeAdult,
          discount: fareItem.discountAdult,
          promotion: fareItem.promotionAdult,
          priceFareCol: priceFareColAdult,
          priceDiscountFareCol: getShowPriceAfterDiscount.value ? fareItem.discountAdult + fareItem.promotionAdult : fareItem.discountAdult,
          priceFeeCol: priceFeeColAdult,
          priceTotalCol: priceFareColAdult + priceFeeColAdult,
        },
        {
          title: 'CHD',
          quantity: child,
          fare: fareItem.fareChild, // giá vé gốc từ hãng
          agencyFee: hideFee ? 0 : agencyFee,
          hideFee,
          surcharge: fareItem.surchargeChild + (hideFee ? agencyFee : 0) + (hideFeeParent ? agencyFeeParent : 0),
          tax: fareItem.taxChild,
          serviceFee: modifiedServiceFee.childAmount !== null ? modifiedServiceFee.childAmount : fareItem.serviceFeeChild,
          discount: fareItem.discountChild,
          promotion: fareItem.promotionChild,
          priceFareCol: priceFareColChild,
          priceDiscountFareCol: getShowPriceAfterDiscount.value ? fareItem.discountChild + fareItem.promotionChild : fareItem.discountChild,
          priceFeeCol: priceFeeColChild,
          priceTotalCol: priceFareColChild + priceFeeColChild,
        },
        {
          title: 'INF',
          quantity: infant,
          fare: fareItem.fareInfant, // giá vé gốc từ hãng
          agencyFee: 0, // hideFee ? 0 : agencyFee,
          hideFee,
          surcharge: fareItem.surchargeInfant,
          tax: fareItem.taxInfant,
          serviceFee: modifiedServiceFee.infantAmount !== null ? modifiedServiceFee.infantAmount : fareItem.serviceFeeInfant,
          discount: fareItem.discountInfant,
          promotion: fareItem.promotionInfant,
          priceFareCol: priceFareColInfant,
          priceDiscountFareCol: getShowPriceAfterDiscount.value ? fareItem.discountInfant + fareItem.promotionInfant : fareItem.discountInfant,
          priceFeeCol: priceFeeColInfant,
          priceTotalCol: isEnableFnSourceAKJSON ? 0 : (priceFareColInfant + priceFeeColInfant), // giá infant lấy trong draft-booking
        },
      ]
      return {
        paxPrice: result,
        total: result.reduce((a, c) => a + (c.priceTotalCol * c.quantity), 0),
      }
    }

    watch(() => [resolveSelectedTrip, getShowPriceAfterDiscount, getCustomFeeServiceDataToModify, getShowPrice], () => {
      totalPrice.value = resolveSelectedTrip.value?.fareOptions
        ? resolveFarePrice(resolveSelectedTrip.value)?.total
        : resolveSelectedTrip.value.trips.reduce((acc, current) => acc + resolveFarePrice(current)?.total || 0, 0)
    }, { deep: true, immediate: true })

    function handleClickTab(index) {
      store.dispatch('app-flight-v2/setTabIndex', index)
    }

    // === COLLAPSE PRICE ==========================
    const isVisiblePrice = ref(draftSelectedTrip.value.map(() => true))
    const rotateStylePrice = index => !isVisiblePrice.value[index] ? 'rotate(0deg)' : 'rotate(180deg)'
    const toggleVisiblePrice = index => {
      isVisiblePrice.value[index] = !isVisiblePrice.value[index]
    }

    // === COLLAPSE TRIPs ==========================
    const isVisibleTrips = ref([])
    const firstTime = ref(true)
    const rotateStyleTripsAll = computed(() => isVisibleTrips.value.every(Boolean) ? 'rotate(0deg)' : 'rotate(-180deg)')
    watchEffect(() => {
      if (firstTime.value && draftSelectedTrip.value.length) {
        isVisibleTrips.value = draftSelectedTrip.value.map(() => true)
        firstTime.value = false
      }
    })
    // toggle all
    const toggleVisibleTrips = () => {
      const isOpenAll = isVisibleTrips.value.every(Boolean)
      if (isOpenAll) {
        isVisibleTrips.value = draftSelectedTrip.value.map(() => false)
      } else {
        isVisibleTrips.value = draftSelectedTrip.value.map(() => true)
      }
    }

    onMounted(() => {
      isVisibleTrips.value = draftSelectedTrip.value.map(() => true)
    })

    // ======================================
    function resolveColorFollowAirline(airline) {
      let textStyle = ''
      if (['VJ', 'VZ'].includes(airline)) textStyle += 'color: #EC2029 !important;'
      if (airline === 'VU') textStyle += 'color: #FFC80B !important;'
      if (airline === 'VN') textStyle += 'color: #166987 !important'
      if (airline === 'QH') textStyle += 'color: #64AC54 !important'
      else textStyle += 'color: #000 !important;'
      return textStyle
    }
    const paxArr = [
      { type: 'Adult', key: 'ADT' },
      { type: 'Child', key: 'CHD' },
      { type: 'Infant', key: 'INF' },
    ]

    function getAirportByAirportCode(airportCode) {
      return store.getters['app-flight-v2/getAirportByAirportCode'](airportCode)
    }
    function getAirportName(airportCode) {
      const airportObj = getAirportByAirportCode(airportCode)
      if (!airportObj) return airportCode

      let airportName
      if (airportObj.group === 'VIỆT NAM' || airportObj.group === 'VIETNAM') {
        airportName = `${this.$t(`vnAirports.${airportCode}`)}`
      } else {
        airportName = airportObj?.group ? (airportObj?.name.split(',')[0] || airportObj?.name) : `${airportObj.city}, ${airportObj.countryName}`
      }
      return airportName
    }

    const isVisiblePriceCombination = ref(true)
    function toggleVisiblePriceCombination() {
      isVisiblePriceCombination.value = !isVisiblePriceCombination.value
    }

    function handleReCalculatePrice() {
      this.$bvModal.show('calculate-trip-price-modal-vn1a')
    }

    const isSelectedAllTrip = computed(() => !draftSelectedTrip.value.some(trip => !trip) || combinationSelectedTrip.value)
    function onClickTakePhotoItineraries() {
      if (!isSelectedAllTrip.value) {
        toastError({ title: this.$t('flight.groupBooking[\'Please select all itineraries!\']') })
        return
      }
      this.$bvModal.show('modal-take-photo-itineraries')
    }

    return {
      searchFlightArray,
      isRTType,
      convertISODateTime,
      convertISODateTimeLangVN,
      formatCurrency,
      toHoursAndMinutes,
      handleClickTab,

      rotateStyleTripsAll,
      isVisibleTrips,
      toggleVisibleTrips,

      isVisiblePrice,
      rotateStylePrice,
      toggleVisiblePrice,
      isVisiblePriceCombination,
      totalPrice,
      convertShortenAircraft,
      draftSelectedTrip,
      resolveSelectedTrip,

      resolveColorFollowAirline,
      paxArr,
      getAirportName,

      getCustomServiceFee,
      isVisiblePriceFareCol,
      isVisibleTotalFareCol,
      isVisibleFeeCol,
      resolveFarePrice,
      toggleVisiblePriceCombination,
      isCombination,

      handleReCalculatePrice,
      isVN1A,
      resolveAirlineFlightNumber,
      // take photo itineraries
      onClickTakePhotoItineraries,
      searchFlightData,
      isHideTakePhotoItineraries,
      isSelectedAllTrip,
      combinationSelectedTrip,
    }
  },
}
</script>

<style lang="scss" scoped>
.rounded_header {
  border-radius: 8px 8px 0 0 !important; // trên trái, trên phải, dưới trái, dưới phải
}

.header_class_toggle {
  border-left: 7px #999999 !important;

  &.active {
    border-left: 7px #efad02 !important;
  }
}

.rotate_class {
  transition: transform 0.3s ease;
}
</style>
