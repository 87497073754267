<template>
  <b-modal
    id="calculate-trip-price-modal-vn1a"
    title="Giá vé"
    title-class="text-airline font-medium-4 font-weight-bolder"
    centered
    no-close-on-backdrop
    size="lg"
    @show="showHandle"
    @hide="hideModal"
  >
    <template #modal-footer="{ close }">
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        size="md"
        variant="outline-secondary"
        class="center rounded-pill"
        @click="close()"
      >
        {{ $t('flight.Back') }}
      </b-button>
    </template>

    <b-alert
      v-if="!isEmpty(discountFare)"
      show
      variant="danger"
      class="py-50 px-1 shadow-sm"
    >
      <b class="fw-700">Giá vé có giá khuyến mãi: <span class="font-medium-2">{{ discountFare }}</span> . Bạn nên đặt tách chặng để có giá khuyến mãi trong trường hợp chỉ có 1 chặng có khuyến mãi !</b>
    </b-alert>

    <div
      v-for="(trip, tripIndex) of selectedTrip"
      :key="tripIndex"
    >
      <div class="font-weight-bolder mb-50">
        Hành trình {{ selectedTrip.length > 1 ? `${tripIndex + 1}` : ':' }}
      </div>
      <b-card
        v-for="(segment, segmentIndex) of trip.segments"
        :key="segmentIndex"
        class="font-weight-bolder border px-2"
        no-body
      >
        <div class="my-75 fw-700">
          <span class="mr-50 text-nowrap">{{ `${resolveAirlineFlightNumber(segment.airline, segment.flightNumber)}` }}</span>
          <span class="mr-50 text-nowrap">{{ `${segment.bookingClass}` }}</span>
          <span class="mr-50 text-nowrap">{{ `${convertISODateTime(segment.departure.at, segment.departure.timezone).dayMonth}` }}</span>
          <span class="mr-50 text-nowrap">{{ `${segment.departure.IATACode}${segment.arrival.IATACode}` }}</span>
          <span class="mr-50 text-nowrap">{{ `${convertISODateTime(segment.departure.at, segment.departure.timezone).hourMin}` }}</span>
          <span>{{ `${convertISODateTime(segment.arrival.at, segment.arrival.timezone).hourMin}` }}</span>
        </div>
      </b-card>
    </div>

    <div>
      <div class="mb-50 font-weight-bolder">
        Chi tiết giá:
      </div>
      <b-table
        outline
        responsive
        bordered
        thead-class="text-center fw-700 text-nowrap"
        tbody-class="text-center"
        :busy="loading"
        :items="payments"
        :fields="priceTableColumns"
      >
        <template
          v-for="(column, indexColumn) in priceTableColumns"
          #[`head(${column.key})`]="{ label }"
        >
          <div :key="indexColumn">
            {{ $t(`reservation.${label}`) }}
          </div>
        </template>

        <template #table-busy>
          <div class="text-warning my-2">
            <p> Đang tải dữ liệu...</p>
            <b-spinner variant="warning" />
          </div>
        </template>

        <template #cell(Passenger)="{item}">
          {{ $t(`reservation.${item[0].paxType}`) }}
        </template>
        <template #cell(fareBasisCode)="{item}">
          {{ item[0].fareBasisCode }}
        </template>
        <template #cell(Net)="{item}">
          {{ `${formatCurrency(item[0].pricingInfo.netFare)}` }}
        </template>
        <template #cell(Tax)="{item}">
          {{ `${formatCurrency(item[0].pricingInfo.taxCalc)}` }}
        </template>
        <template #cell(Fare)="{item}">
          {{ `${formatCurrency(item[0].pricingInfo.totalCalc)}` }}
        </template>
        <template #cell(Amount)="{item}">
          {{ item.length }}
        </template>
        <template #cell(total)="{item}">
          <div class="font-weight-bolder">
            {{ `${formatCurrency(item.reduce((total, it) => total + it.pricingInfo.totalCalc, 0))}` }}
          </div>
        </template>
      </b-table>

      <div
        v-if="!isEmpty(payments)"
        class="text-airline fw-600 mt-2"
      >
        <div class="text-center">
          <span class="mr-2">
            Tổng tiền:
          </span>
          <span class="font-medium-3 fw-700">
            {{ formatCurrency(totalPriceBooking) }}
          </span>
        </div>
        <div class="text-center text-warning">
          <span class="font-italic">Lưu ý: Tổng tiền chưa bao gồm phí dịch vụ</span>
        </div>
      </div>
    </div>

    <div
      v-if="totalDiscountAmount"
      class="text-right mb-50"
    >
      <span class="mr-2">
        Chiết khấu <em>(tạm tính)</em>:
      </span>

      <span class="font-medium-3 font-weight-bolder">
        {{ formatCurrency(totalDiscountAmount) }}
      </span>
    </div>

    <!-- <div>
      <div class="mb-50 font-weight-bolder">
        Giá vé:
      </div>
      <b-table
        outline
        responsive
        bordered
        thead-class="text-center fw-700"
        tbody-class="text-center"
        :busy="loading"
        :items="dataTable"
        :fields="priceTableColumns"
      >
        <template
          v-for="(column, indexColumn) in priceTableColumns"
          v-slot:[`head(${column.key})`]="{ label }"
        >
          <div :key="indexColumn">
            {{ $t(`reservation.${label}`) }}
          </div>
        </template>

        <template #table-busy>
          <div class="text-warning my-2">
            <p> Đang tải dữ liệu...</p>
            <b-spinner variant="warning" />
          </div>
        </template>

        <template #cell(Passenger)="{item}">
          {{ $t(`reservation.${item[0].paxType}`) }}
        </template>
        <template #cell(Net)="{item}">
          {{ `${formatCurrency(item[0].pricingInfo.netFare)}` }}
        </template>
        <template #cell(Tax)="{item}">
          {{ `${formatCurrency(item[0].pricingInfo.tax)}` }}
        </template>
        <template #cell(Fare)="{item}">
          {{ `${formatCurrency(item[0].pricingInfo.total)}` }}
        </template>
        <template #cell(Amount)="{item}">
          {{ item.length }}
        </template>
        <template #cell(total)="{item}">
          <div class="font-weight-bolder">
            {{ `${formatCurrency(item.reduce((total, it) => total + it.pricingInfo.total, 0))}` }}
          </div>
        </template>
      </b-table>
    </div>
    <div
      v-if="false"
      class="text-warning"
    >
      <div class="text-right mb-50">
        <span class="mr-2">
          Tổng tiền hành trình:
        </span>
        <span class="font-medium-3 fw-700">
          {{ getTotalOneTrip(payments) }}
        </span>
      </div>
      <div class="text-right">
        <span class="font-italic">Lưu ý: Tổng tiền chưa bao gồm phí dịch vụ</span>
      </div>
    </div> -->
  </b-modal>
</template>

<script>
import {
  BModal, BButton, BCard, BTable, BSpinner, BAlert,
} from 'bootstrap-vue'
import {
  ref, computed, toRefs,
} from '@vue/composition-api'
import isEmpty from 'lodash/isEmpty'
import groupBy from 'lodash/groupBy'

import { getUserData } from '@/api/auth/utils'
import { formatCurrency, convertISODateTime, resolveAirlineFlightNumber } from '@/@core/utils/filter'
import store from '@/store'

import useBookingHandle from '@flightv2/useBookingHandle'

export default {
  components: {
    BModal,
    BCard,
    BButton,
    BTable,
    BSpinner,
    BAlert,
  },
  props: {
    selectedTrip: {
      type: [Array, null],
      default: () => null,
    },
  },
  setup(props) {
    const priceTableColumns = [
      { label: 'passengerType', key: 'Passenger' },
      { label: 'fareBasisCode', key: 'fareBasisCode' },
      { label: 'net', key: 'Net' },
      { label: 'tax', key: 'Tax' },
      { label: 'total', key: 'Fare' },
      { label: 'amount', key: 'Amount' },
      { label: 'totalPrice', key: 'Total' },
    ]

    function getPaxType(pt) {
      if (pt === 'ADT') return 'ADULT'
      if (['CHD', 'CNN'].includes(pt)) return 'CHILD'
      if (pt === 'INF') return 'INFANT'
      return pt
    }

    const {
      payNowConfirmPrice,
      getSearchFlight,
    } = useBookingHandle()

    const { selectedTrip } = toRefs(props)
    const loading = ref(false)

    // const dataTable = ref([])
    // const totalPrice = ref(0)

    const payments = ref(null)
    const totalPriceBooking = ref(0)

    const totalAgencyFee = ref(0)
    const totalAgencyFeeParent = ref(0) // phí xuất vé F1 => F2 ẩn phí, F3 sẽ chịu phí này
    const totalDiscountAmount = computed(() => selectedTrip.value?.reduce((a, c) => a + ((c?.fareOptions?.discountAdult || 0) + (c?.fareOptions?.discountChild || 0) + (c?.fareOptions?.discountInfant || 0)) || 0, 0))
    const hideFee = computed(() => store.getters['userStore/getHideFee'])
    const hideFeeParent = computed(() => store.getters['userStore/getHideFeeParent'])

    const regexFareDiscount = /^[A-Z0-9]AP[A-Z0-9]*$/
    const discountFare = computed(() => selectedTrip.value?.find(trip => regexFareDiscount.test(trip?.fareOptions?.fareBasisCode))?.fareOptions?.fareBasisCode || '')

    function showHandle() {
      loading.value = true
      const numberPax = getSearchFlight.value.adult + getSearchFlight.value.child

      if (!store.getters['userStore/getRoleMama']) {
        // ANCHOR: Get agency Fee
        const itineraries = selectedTrip.value

        itineraries.forEach(trip => {
          const agFee = store.getters['userStore/getAgencyFee'](trip.domestic, trip.source)
          const agFeeParent = store.getters['userStore/getAgencyFee'](trip.domestic, trip.source, true)
          // FIXME agFee with transits
          totalAgencyFee.value += (agFee?.amount || 0) * numberPax
          totalAgencyFeeParent.value += (agFeeParent?.amount || 0) * numberPax
        })
      }

      const paxLists = [ // tính giá VN1A auto lấy num pax search
        {
          paxType: 'ADULT',
          firstName: 'NAM',
          lastName: 'NGUYEN',
          title: 'MR',
          PaxDocuments: [],
        },
      ]
      const itineraries = []
      selectedTrip.value.forEach(trip => {
        itineraries.push(
          {
            domestic: trip?.domestic || false, // for calculate agencyFee
            source: trip.source,
            airline: trip?.airline || null,
            clientId: trip?.clientId || '',
            bookingKey: trip?.bookingKey || '',
            itineraryId: trip?.itineraryId?.toString() || null,
            fareBreakdowns: [...new Set(paxLists.map(p => p.paxType))].map(paxType => ({
              paxType,
              netFare: 0,
              discountAmount: 0,
              discountAmountParent: 0,
              tax: 0,
              total: 0,
              fareValue: trip?.fareOptions?.fareValue || '',
            })),
            segments: trip.segments.map(segment => {
              const fareBasisCodeSplit = trip.fareOptions?.fareBasisCode?.split(' | ') || ''
              const bookingClassSplit = trip.fareOptions.bookingClass.split(' | ')
              const groupClassSplit = trip.fareOptions.groupClass.split(' | ')
              return {
                leg: segment.leg,
                airline: segment.airline,
                departure: segment.departure.IATACode,
                arrival: segment.arrival.IATACode,
                departureTime: convertISODateTime(segment.departure.at, segment.departure.timezone).ISOdatetime,
                arrivalTime: convertISODateTime(segment.arrival.at, segment.arrival.timezone).ISOdatetime,
                flightNumber: segment.flightNumber.toString(),
                fareType: trip.fareOptions.fareType.split(' | ')[segment.leg - 1]?.toString() || trip?.fareOptions?.fareType,
                fareBasisCode: fareBasisCodeSplit[segment.leg - 1]?.toString() || fareBasisCodeSplit[fareBasisCodeSplit.length - 1]?.toString() || trip?.fareOptions?.fareBasisCode,
                bookingClass: bookingClassSplit[segment.leg - 1]?.toString() || segment.bookingClass,
                groupClass: groupClassSplit[segment.leg - 1]?.toString() || trip?.fareOptions?.groupClass,
                marriageGrp: segment?.marriageGrp,
                segmentValue: segment?.segmentValue || '',
                bookingClassId: segment?.bookingClassId || '',
                segmentId: segment?.segmentId || (segment?.airlineSegmentId ? String(segment?.airlineSegmentId) : ''),
                operating: segment?.operating || '',
              }
            }),
            discountInfo: undefined,
            discountParent: undefined,
            paxssr: [],
            paxSeat: [],
          },
        )
      })
      const payload = {
        type: 'VN1A',
        contact: getUserData().employeeData.id,
        flightType: getSearchFlight.value?.type,
        airlineContact: {
          phoneNumber: '0969999999',
          email: 'test123@abc.com',
        },
        paxLists,
        itineraries,
        isSplitItineraries: true,
      }

      payNowConfirmPrice(payload)
        .then(res => {
          const mapRes = res.paxLists?.map(pax => {
            const totalAgFee = ((!['INFANT', 'INF'].includes(getPaxType(pax.paxType)) && hideFee.value)
              ? (totalAgencyFee.value / numberPax)
              : 0)
            const totalAgFeeParent = ((!['INFANT', 'INF'].includes(getPaxType(pax.paxType)) && hideFeeParent.value)
              ? (totalAgencyFeeParent.value / numberPax)
              : 0)
            return {
              paxType: getPaxType(pax.paxType),
              fareBasisCode: res.itineraries.flatMap(trip => trip.map(segment => segment.fareBasisCode)).join(' | '),
            pricingInfo: {
                ...pax.pricingInfo,
                taxCalc: pax.pricingInfo.tax + totalAgFee + totalAgFeeParent,
                totalCalc: pax.pricingInfo.total + totalAgFee + totalAgFeeParent,
              },
            }
          })

          totalPriceBooking.value = (mapRes.reduce((total, item) => total + item.pricingInfo.total, 0)
              + (hideFee.value ? totalAgencyFee.value : 0)
              + (hideFeeParent.value ? totalAgencyFeeParent.value : 0)
              - (totalDiscountAmount.value ? totalDiscountAmount.value : 0))

          const groups = groupBy(mapRes, 'paxType')
          payments.value = Object.values(groups)
        })
        .catch(e => {
          console.error({ e })
        })
        .finally(() => {
          loading.value = false
        })
    }

    function hideModal() {
      payments.value = null
      totalPriceBooking.value = 0
      totalAgencyFee.value = 0
      totalAgencyFeeParent.value = 0
    }

    return {
      loading,
      isEmpty,
      formatCurrency,
      convertISODateTime,

      showHandle,
      hideModal,
      discountFare,
      payments,

      priceTableColumns,
      totalDiscountAmount,
      totalPriceBooking,
      resolveAirlineFlightNumber,
    }
  },
}
</script>
